import faBadgeCheckSolid from "icons/solid/badge-check.svg";
import faBookmarkSolid from "icons/solid/bookmark.svg";
import faBookOpenSolid from "icons/solid/book-open.svg";
import faStopCircleSolid from "icons/solid/circle-stop.svg";
import faTrashCanSolid from "icons/solid/trash-can.svg";

import faBadgeCheckRegular from "icons/regular/badge-check.svg";
import faBookmarkRegular from "icons/regular/bookmark.svg";
import faBookOpenRegular from "icons/regular/book-open.svg";
import faStopCircleRegular from "icons/regular/circle-stop.svg";
import faTrashCanRegular from "icons/regular/trash-can.svg";

import faBadgeCheckDuo from "icons/duotone/badge-check.svg";
import faBookmarkDuo from "icons/duotone/bookmark.svg";
import faBookOpenDuo from "icons/duotone/book-open.svg";
import faStopCircleDuo from "icons/duotone/circle-stop.svg";
import faTrashCanDuo from "icons/duotone/trash-can.svg";

import UserBookStatusType from "types/UserBookStatusType";
import { IconThemeProps } from "hardcover-ui/theme/components/icon";

export const WantToReadStatus = {
  id: 1,
  name: "Want to Read",
  icon: faBookmarkRegular,
  activeIcon: faBookmarkSolid,
  duoIcon: faBookmarkDuo,
};
export const CurrentlyReadingStatus = {
  id: 2,
  name: "Currently Reading",
  icon: faBookOpenRegular,
  activeIcon: faBookOpenSolid,
  size: "1x",
  duoIcon: faBookOpenDuo,
};
export const ReadStatus = {
  id: 3,
  name: "Read",
  icon: faBadgeCheckRegular,
  activeIcon: faBadgeCheckSolid,
  duoIcon: faBadgeCheckDuo,
};
export const DidNotFinishStatus = {
  id: 5,
  name: "Did Not Finish",
  icon: faStopCircleRegular,
  activeIcon: faStopCircleSolid,
  duoIcon: faStopCircleDuo,
};
export const RemoveStatus = {
  id: 6,
  name: "Remove",
  icon: faTrashCanRegular,
  activeIcon: faTrashCanSolid,
  duoIcon: faTrashCanDuo,
};

export const STATUSES: UserBookStatusType[] = [
  WantToReadStatus,
  CurrentlyReadingStatus,
  ReadStatus,
  DidNotFinishStatus,
  RemoveStatus,
];

export const STATUS_MAP = {
  0: {
    id: 0,
    name: "Want to Read",
    icon: faBookmarkRegular,
    duoIcon: faBookmarkDuo,
    slug: "want-to-read",
  },
  1: {
    id: 1,
    name: "Want to Read",
    icon: faBookmarkRegular,
    duoIcon: faBookmarkDuo,
    slug: "want-to-read",
  },
  2: {
    id: 2,
    name: "Currently Reading",
    icon: faBookOpenRegular,
    duoIcon: faBookOpenDuo,
    size: "1x" as IconThemeProps["size"],
    slug: "currently-reading",
  },
  3: {
    id: 3,
    name: "Read",
    icon: faBadgeCheckRegular,
    duoIcon: faBadgeCheckDuo,
    slug: "read",
  },
  5: {
    id: 5,
    name: "Did Not Finish",
    icon: faStopCircleRegular,
    duoIcon: faStopCircleDuo,
    slug: "did-not-finish",
  },
  6: {
    id: 6,
    name: "Remove",
    icon: faTrashCanRegular,
    duoIcon: faTrashCanDuo,
  },
};
