"use client";

import { Suspense, lazy } from "react";
import { useIsScreenSizeBiggerThanTailwindSize } from "hooks/useIsScreenSizeBiggerThanTailwindSize";
import { DrawerSettingsType } from "../types/types";
import DrawerLoading from "./DrawerLoading";

const DrawerBottomDrawer = lazy(() => import("./DrawerBottomDrawer"));
const DrawerDropdown = lazy(() => import("./DrawerDropdown"));
const DrawerSidebar = lazy(() => import("./DrawerSidebar"));

type DrawerVariant = "dropdown" | "left" | "popover";
function Drawer({
  variant = "dropdown",
  children,
  settings,
}: {
  variant?: DrawerVariant;
  children: React.ReactElement;
  settings?: DrawerSettingsType;
}) {
  const isLarge = useIsScreenSizeBiggerThanTailwindSize("lg");

  return (
    <Suspense>
      {isLarge ? (
        <>
          {variant === "dropdown" ? (
            <DrawerDropdown settings={settings}>{children}</DrawerDropdown>
          ) : (
            <DrawerSidebar settings={settings}>{children}</DrawerSidebar>
          )}
        </>
      ) : (
        <DrawerBottomDrawer settings={settings}>{children}</DrawerBottomDrawer>
      )}
    </Suspense>
  );
}

Drawer.Loading = DrawerLoading;

export default Drawer;
