"use client";

import { Suspense, lazy } from "react";
import { useIsScreenSizeBiggerThanTailwindSize } from "hooks/useIsScreenSizeBiggerThanTailwindSize";
import { DrawerSettingsType } from "../types/types";
import LoadingSpinner from "hardcover-ui/components/LoadingSpinner";

const DrawerBottomDrawer = lazy(() => import("./DrawerBottomDrawer"));
const DrawerDropdown = lazy(() => import("./DrawerDropdown"));
const DrawerSidebar = lazy(() => import("./DrawerSidebar"));

type DrawerVariant = "dropdown" | "left" | "popover";
export default function DrawerLoading({
  variant = "dropdown",
  settings,
}: {
  variant?: DrawerVariant;
  settings?: DrawerSettingsType;
}) {
  const isLarge = useIsScreenSizeBiggerThanTailwindSize("lg");

  return (
    <Suspense>
      {isLarge ? (
        <>
          {variant === "dropdown" ? (
            <DrawerDropdown settings={settings}>
              <div className="w-64">
                <LoadingSpinner variant="layout" />
              </div>
            </DrawerDropdown>
          ) : (
            <DrawerSidebar settings={settings}>
              <div className="w-64">
                <LoadingSpinner variant="layout" />
              </div>
            </DrawerSidebar>
          )}
        </>
      ) : (
        <DrawerBottomDrawer settings={settings}>
          <LoadingSpinner variant="layout" />
        </DrawerBottomDrawer>
      )}
    </Suspense>
  );
}
